export interface PatientPayer {
  id: string | null;
  effectiveDate?: any;
  name?: string;
  insuranceCompanyId?: string;
  payerId?: string;
  endDate?: any;
  termDate?: any;
  subscriberFirstName?: string;
  subscriberMiddleInitial?: string;
  subscriberLastName?: string;
  patientRelationshipToSubscriber?: string;
  subscriberIdNumber?: string;
  subscriberGroupNumber?: string;
  patientId?: string;
  releaseOfInformation?: string;
  assignmentOfBenefits?: string;
  subscriberDateOfBirth?: Date;
  subscriberSex?: string;
  subscriberAddress1?: string;
  subscriberAddress2?: string;
  subscriberCity?: string;
  subscriberState?: string | null;
  subscriberCountry?: string | null;
  subscriberZip?: string;
  sameAsPatient?: boolean;
  updateClaimsEvent?: boolean;
}


export interface PatientInsurance {
  id?: string;
  name: string;
  payerId?: string;
}

export const selfPayer = { name: "Self", payerId: "Self", fullName: "Self", subscriberId: "" };